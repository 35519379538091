import React from 'react'
import Img1 from '../images/Escalera.jpg'
import { useStaticQuery, graphql } from "gatsby";
import styled from '@emotion/styled'
import Image from 'gatsby-image';

const IMGBACKGROUND = styled.div`
    background: url(${Img1}) center center no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 758px;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`;

const AlianzesLogoContainer = styled.div`
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-wrap: wrap;
    & .logos-container{
        width: 95%;
        margin: auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
    }
    & a{
        width: 30%;
        max-width: 215px;

    }
    @media only screen and (max-width: 850px){
        min-height: unset;
        margin-top: 1rem;
    }
    @media only screen and (max-width: 475px){
        & a{
            width: 110px;

        }
    }
`

const Alianzascomponent = () => {

	const { allContentfulGeneral } = useStaticQuery(graphql`
      query {
          allContentfulGeneral(filter: {node_locale: {eq: "en-US"}}, limit: 1) {
              nodes {
                  alianzas {
                      json
                  }
                  alliancesLinks
                  alliancesLogos {
                      id
                      fluid {
                          base64
                          aspectRatio
                          src
                          srcSet
                          sizes
                      }
                  }
              }
          }
      }
  `)

	const { alliancesLinks, alliancesLogos } = allContentfulGeneral.nodes[0]
	return (
		<div>
			<div className="service about-us">
				<div className="SocialDivMediaContainer">
				</div>
				<div className="service-subcontainer about-us-subcontainer">
					<div className="menu-left">
						<div style={{ 'height': '100px' }}>
							<h1>ALIANZAS</h1>
						</div>
						<div className="subtittle2">
							<p>
								En UPC Cultural
								trabajamos en alianza
								con artistas, instituciones
								y empresas
								comprometidos con la
								promoción de
								manifestaciones
								artístico-culturales
								nacionales e
								internacionales.<br></br><br></br>
								¡Gracias a todos ellos!
							</p>
							<div>
								<h3>UPC Cultural es + Arte y cultura</h3>
							</div>
						</div>
						<AlianzesLogoContainer className="responsive_container">
							<div className="logos-container">
								{alliancesLogos && alliancesLogos.map((Logo, i) => (
									<a target="_blank" rel="noreferrer" href={alliancesLinks ? alliancesLinks[i] : ''} key={Logo.id}>
										<Image fluid={Logo.fluid} />
									</a>
								))}
							</div>
						</AlianzesLogoContainer>
					</div>
				</div>
				<IMGBACKGROUND>
					<AlianzesLogoContainer className="normal_container">
						<div className="logos-container">
							{alliancesLogos && alliancesLogos.map((Logo, i) => (
								<a target="_blank" rel="noreferrer" href={alliancesLinks ? alliancesLinks[i] : ''} key={Logo.id}>
									<Image fluid={Logo.fluid} />
								</a>
							))}
						</div>
					</AlianzesLogoContainer>
				</IMGBACKGROUND>
			</div>
		</div>
	)
}

export default Alianzascomponent;
