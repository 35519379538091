import React from 'react'
import Layout from "../components/share/layout";
import Alianzascomponent from "../components/alianzascomponent"
import '../styles/font-awesome-4.7.0/css/font-awesome.css'
import Footer from '../components/share/footer';

const nosotros = () => {
	return (
		<Layout
			description="Entérate de las exhibiciones artísticas y culturales que han sido parte de la Galería de UPC Cultural."
			title="Exhibiciones | UPC Cultural"
			subtitile="Entérate de las exhibiciones artísticas y culturales que han sido parte de la Galería de UPC Cultural."
			imgurl="//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg"
			metaTitle="Exhibiciones | UPC Cultural"
			metaKeywords="exhibicion, exhibiciones, galeria, UPC, UPC Cultural"
			metaDescripcin="Entérate de las exhibiciones artísticas y culturales que han sido parte de la Galería de UPC Cultural."
		>
			<Alianzascomponent />
			<Footer />
		</Layout>
	)
}

export default nosotros
